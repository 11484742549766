.database {
  display: flex;
  &-left {
    width: 30%;
    min-width: 330px;
    flex-shrink: 0;
    position: relative;
    @media (max-width: 1095px) {
      min-width: 240px;
      width: 240px;
    }
  }
  &-right {
    width: 70%;
    position: relative;
    height: calc(100vh);
    overflow-y: scroll;
    padding-bottom: 100px;
    flex-grow: 1;
  }
  &-info {
    display: flex;
    background: linear-gradient(
      270deg,
      rgb(196 196 196 / 35%) -53%,
      rgba(196, 196, 196, 0) 127.57%
    );
    align-items: center;
    padding: 0 15px;
    height: 69px;
    > img {
      width: 40px;
      height: 90%;
      object-fit: contain;
      margin-right: 10px;
    }
    > span {
      text-transform: capitalize;
      font-size: 18px;
      font-weight: 300;
    }
    > button {
      margin-left: 10px;
      height: 40px;
      padding: 0 15px;
      background-color: #fff;
      border: 1px solid rgb(179, 63, 63);
      color: rgb(179, 63, 63);
      background: transparent;
      outline: none;
      transition: 0.3s;
      cursor: pointer;
      &.editBtn {
        margin-left: auto;
        border: 1px solid rgb(148, 148, 148);
        color: rgb(143, 143, 143);
        &:hover {
          background: rgb(77, 77, 77);
        }
      }
      i {
        margin-right: 10px;
      }
      &:hover {
        color: #fff;
        background: rgb(179, 63, 63);
      }
    }
  }
}
.ant-radio,
.ant-radio-wrapper {
  color: #fff !important;
}
.popup {
  position: sticky;
  background: #0f0f0f2e;
  backdrop-filter: blur(4px);
  width: 100%;
  left: 0;
  top: 0;
  height: 100vh;
  z-index: 3;
  padding-top: 69px;
  padding-bottom: 69px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: -100vh;
  overflow-y: auto;

  &-content {
    background: #383838;
    color: #fff;
    padding: 25px;
    width: 100%;
    max-width: 330px;
    display: flex;
    flex-flow: column;
    border: 1px solid #222;
    overflow: hidden;
    border-radius: 20px;
    margin: auto;
    &[data-popup*="edit_table"] {
      max-width: 90%;
    }
    .header {
      font-size: 16px;
      margin-bottom: 10px;
      font-weight: 600;
    }
    .ant-radio-group {
      display: flex;
    }
    .ant-input,
    .ant-select {
      width: 100%;
      background: #7d7d7d;
      color: #fff;
      border: 0 !important;
    }
    > div {
      display: flex;
      flex-flow: column;
      margin-bottom: 10px;
      .ant-checkbox-wrapper {
        margin-top: 10px;
        color: #fff;
      }
      label:not(.ant-checkbox-wrapper) {
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 25px;
        background-color: #7d7d7d;
        cursor: pointer;
        padding: 10px;
        &.ant-radio-wrapper {
          height: 31px;
          font-size: 15px;
          background: #0000000f;
          &:nth-child(1) {
            background: #d7d7d7;
            color: var(--main) !important;
            clip-path: polygon(0 0, 100% 0%, 90% 100%, 0% 100%);
          }
          &:nth-child(2) {
            background: var(--main);
            color: #fff !important;
            clip-path: polygon(10% 0, 100% 0%, 100% 100%, 0% 100%);
          }
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        + input {
          display: none;
        }
      }
    }
    > button {
      height: 40px;
      background: var(--main);
      color: #fff;
      border: 0;
      cursor: pointer;
    }
  }
}
.noCatSelected {
  font-size: 25px;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.noTable {
  width: calc(100% - 40px);
  height: 200px;
  background: #8181811a;
  margin: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  span {
    font-size: 20px;
  }
  button {
    background: transparent;
    border: 0;
    background: #fff;
    color: #000;
    padding: 10px 20px;
  }
}
.createTable {
  display: flex;
  width: 100%;
  flex-flow: column;
  margin-top: 30px;
  width: calc(100% - 40px);
  margin-left: auto;
  margin-right: auto;
  &-name {
    background: rgb(58, 58, 58);
    height: 40px;
    display: flex;
    align-items: center;
    input {
      width: calc(100% - 90px);
      height: 100%;
      border: 0;
      outline: none;
      background: transparent;
      padding: 0 10px;
    }
    button {
      width: auto;
      flex-grow: 1;
      background: #474747;
      height: 40px;
      border: 0;
      outline: 0;
    }
  }
  &-cols {
    background: rgb(26, 26, 26);
    display: flex;
    align-items: stretch;
    overflow-y: auto;
    height: 160px;
  }
  &-col {
    display: flex;
    flex-flow: column;
    width: 20%;
    flex-shrink: 0;
    border-right: 1px solid #3b3b3b;

    input,
    select,
    .ant-select,
    .ant-input,
    .ant-select-selector {
      width: 100%;
      background: transparent;
      border: 0 !important;
      outline: none;
    }
    input {
      padding: 0 10px;
      height: 40px;
    }
    &_name {
      border-bottom: 1px solid #3b3b3b;
    }
  }
  &-add {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    background: #555555;
    color: #338f87;
    flex-shrink: 0;
  }
}
.database .table {
  width: 100%;
  display: flex;
  flex-flow: column;
  padding: 20px;
  > :not(:first-child) {
    border-top: 0 !important;
  }
  &--info {
    background: linear-gradient(
      270deg,
      rgb(196 196 196 / 35%) -53%,
      rgba(196, 196, 196, 0) 127.57%
    );
    height: 40px;
    align-items: center;
    padding: 0 10px;
    display: flex;
    cursor: pointer;
    transition: background 0.3s;
    &:hover {
      background: linear-gradient(
        270deg,
        rgb(196 196 196 / 35%) -43%,
        rgba(110, 110, 110, 0.014) 127.57%
      );
    }
    > i:first-child {
      cursor: pointer;
      margin-right: 10px;
    }
    > span:first-child {
      font-size: 14px;
      text-transform: capitalize;
    }
    > button {
      background: var(--main);
      border: 0;
      outline: 0;
      font-size: 13px;
      color: #fff;
      margin-left: 15px;
      padding: 0 15px;
      padding-left: 5px;
      height: 27px;
      min-width: 27px;
      cursor: pointer;
      &.assigned {
        background: #fff;
        border: 1px solid var(--main);
        color: #0ca10c;
        font-weight: 600;
        pointer-events: none;
        i {
          margin-right: 0;
        }
      }
      &.filter {
        margin-left: auto;
        padding-right: 0;
      }
      i {
        margin-right: 5px;
      }
    }
  }
  &--header {
    display: flex;
    border-bottom: 1px solid #4a4a4a;
    border-left: 1px solid #4a4a4a;
    border-right: 1px solid #4a4a4a;
    border-top: 1px solid #4a4a4a;
    width: 100%;
    margin-left: auto;
    position: relative;
    .edit {
      display: none;
      position: absolute;
      left: 0;
      top: 1px;
      width: 60px;
      flex-shrink: 0;
      transform: translateX(-100%);
      border-left: 1px solid #4a4a4a;
      border-bottom: 1px solid #4a4a4a;
      height: 100%;
      cursor: pointer;
      transition: 0.3s;
    }
    &.editing {
      width: calc(100% - 60px);
      .edit {
        display: flex;
      }
    }
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      background: #00000000;
      width: 100%;
      &.actions {
        flex-shrink: 0;
        width: 80px;
        i {
          cursor: pointer;
        }
      }
    }
  }
  &--row {
    display: flex;
    border-bottom: 1px solid #4a4a4a;
    border-left: 1px solid #4a4a4a;
    border-right: 1px solid #4a4a4a;
    width: 100%;
    transition: 0.3s;
    margin-left: auto;
    width: 100%;
    position: relative;
    &.isEditing {
      box-shadow: 0 0 13px -5px #000;
      z-index: 3;
      position: relative;
      transform-origin: center;
      transform: scale(1.02);
    }
    .edit {
      display: none;
      position: absolute;
      left: 0;
      top: 1px;
      width: 60px;
      flex-shrink: 0;
      transform: translateX(-100%);
      border-left: 1px solid #4a4a4a;
      border-bottom: 1px solid #4a4a4a;
      height: 100%;
      cursor: pointer;
      transition: 0.3s;
      justify-content: space-evenly;
      align-items: center;
      i {
        &:hover {
          font-weight: 600;
        }
      }
    }
    &.editing {
      width: calc(100% - 60px);
      .edit {
        display: flex;
      }
    }
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      background: #e9e9e900;
      border-right: 1px solid #4a4a4a;
      flex-shrink: 1;
      width: 100%;
      &.actions {
        flex-shrink: 0;
        width: 80px;
        i {
          cursor: pointer;
        }
      }
      > input {
        width: 100%;
        background: transparent;
        border: 0;
        outline: 0;
        height: 100%;
        padding: 0 10px;
      }
      &:last-child {
        border-right: 0;
      }
    }
  }
  &--actions {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-bottom: 1px solid #4a4a4a;
    border-left: 1px solid #4a4a4a;
    border-right: 1px solid #4a4a4a;

    button {
      background: transparent;
      border: 0;
      background-color: #e1e1e117;
      padding: 0 20px;
      height: 40px;
      margin-right: 10px;
      cursor: pointer;
      border: 1px solid #4a4a4a;
      transition: 0.3s;
      &:hover {
        border-color: rgb(170, 170, 170);
      }
    }
  }
  &--pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-bottom: 1px solid #4a4a4a;
    border-left: 1px solid #4a4a4a;
    border-right: 1px solid #4a4a4a;
    .ant-pagination-options-quick-jumper,
    .ant-pagination-jump-prev
      .ant-pagination-item-container
      .ant-pagination-item-ellipsis,
    .ant-pagination-jump-next
      .ant-pagination-item-container
      .ant-pagination-item-ellipsis {
      color: #fff;
    }
  }
}
.addTable {
  margin-left: auto;
  margin-right: auto;
  display: block;
  background: transparent;
  border: 1px solid var(--main);
  padding: 0 14px;
  height: 40px;
  color: var(--main);
  transition: 0.3s;
  margin-top: 20px;
  &:hover {
    background: var(--main);
    color: #fff;
  }
}

button.submit {
  background: transparent;
  color: var(--main);
  border: 1px solid var(--main);
  transition: 0.3s;
  height: 40px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  &:hover {
    background: var(--main);
    color: #fff;
  }
}

html {
  .ant-pagination-item-active a,
  .ant-pagination-item-active {
    color: var(--main);
    border-color: var(--min);
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background: var(--main) !important;
    border-color: var(--main) !important;
  }
  .ant-pagination-item:hover {
    a {
      color: var(--main);
    }
    border-color: var(--main) !important;
  }
}
.ant-select {
  color: #fff !important;
}


