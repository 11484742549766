@import "../../styles/index.scss";

.sidenav {
  height: 100vh;
  color: white;
  background: #1d1e1f;
  overflow-x: hidden;
  display: flex;
  flex-flow: column;

  h1 {
    text-align: center;
  }
  .h5_ {
    background: #1d1e1f;
    height: 69px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 32px;
    color: #5da7a2;
    line-height: 39px;
    letter-spacing: 0.21em;
  }

  h5,
  h1 {
    margin-bottom: 0;
  }

  .decorator {
    text-decoration: none;
    color: white;
  }

  .createCat {
    margin-top: auto;
    width: 90%;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    height: 80px;
    text-transform: uppercase;
    background: transparent;
    border: 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */

    color: #27292a;
    transition: 0.3s;
    background: url("../../assets/png/createbtn.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    border: 0;
    cursor: pointer;
    i {
      margin-right: 5px;
    }
  }
}
.roles {
  .links-wrapp {
    margin-top: 20px;
  }
}
.links-wrapp {
  width: 100%;
  display: flex;
  flex-flow: column;
  margin-top: 50px;
  padding: 0 20px;

  .catBtn,
  .roleBtn {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    &.active {
      .name {
        background: #fff;
        padding: 0 10px;
        color: var(--main);
        border-radius: 20px;
        box-shadow: 1px 1px 5px -2px #000;
      }
    }
    .name {
      font-size: 26px;
      cursor: pointer;
      text-transform: capitalize;
      transition: 0.3s;
    }
    i {
      margin-right: 10px;
      width: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}
.roles {
  .dropDown:before {
    border-right: 1px dotted #0000007a;
  }
  .dropDown .roleBtn.active::before,
  .dropDown .roleBtn:last-child::before {
    border-bottom: 1px dotted #2020207a;
  }
}
.top-menu {
  height: 69px;
  display: flex;
  width: 100%;
  color: white;
  background: var(--main-dark);
  position: sticky;
  z-index: 1;
  top: 0;
  left: 0;
  align-items: center;
  padding: 0 15px;
  > button {
    background: transparent;
    border: 1px solid #fff;
    color: #fff;
    padding: 0 10px;
    height: 40px;
    display: flex;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
    transition: 0.3s;
    &.active,
    &:hover {
      background: #fff;
      color: var(--main);
      border-color: var(--main);
    }
    i {
      margin-right: 10px;
    }
  }
  > i.fal.fa-home {
    cursor: pointer;
    font-size: 22px;
  }
  &.database {
    color: white;
    background: #313131;
  }

  .right {
    margin-left: auto;
    display: flex;
    align-items: center;
    > i {
      margin-left: auto;
      font-size: 25px;
      color: #fff;
      cursor: pointer;
    }
  }
  .inpWrapp {
    position: relative;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 22px;
    height: 38px;
    margin-right: 20px;
    display: none;

    > i {
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
    input {
      background: transparent;
      border: 0;
      outline: none;
      padding: 0 20px;
      height: 100%;
      width: 100%;
      color: #fff;
      padding-left: 45px;
    }
  }
}
.dropDown {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  margin: auto;
  padding-left: 20px;
  flex-flow: column;
  left: 6px;
  margin-bottom: 10px;
  position: relative;
  transition: 0.3s;
  &:before {
    content: "";
    position: absolute;
    left: 1px;
    width: 1px;
    height: calc(100% + 5px);
    border-right: 1px dotted #ffffff7a;
    display: block;
    top: -18px;
  }

  .catBtn.active,
  .catBtn:last-child,
  .roleBtn.active,
  .roleBtn:last-child {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      transform: translateX(-130%);
      height: 1px;
      border-bottom: 1px dotted #ffffff7a;
      width: 15px;
      bottom: 12px;
    }
  }
  .catBtn:last-child,
  .roleBtn:last-child {
    margin-bottom: 0;
  }
  > div {
    display: flex;
    > i {
      margin-right: 10px;
      font-size: 14px;
    }
    > a {
      margin-top: 0 !important;
    }
  }
}
