@import "./sass/fonts";
@import "./sass/globals";
@import "./sass/themes";
@import "./sass/common";
@import "./font-awsome.min.scss";
@import "./anim.scss";

:root {
  --main: #5da7a2;
  --main-dark: #4b928d;
  --primary: #1d1e1f;
  --primary-20: #161718;
  --primary-8: #06466375;
  --primary-light: #313233;
  --primary-lighter: #b4d6da;
  --primary-lighter-x: #8ec1c7;
  --primary-lighter-xl: #244a4f87;
  --primary-dark: #12727e;
  --primary-darker: #103d43;
  --secondary: #7b3d27;
  --secondary-60: rgba(91, 36, 15, 0.65);
  //other colors
  --default-white: rgb(255, 255, 255);
  --default-black: #000;
  --default-black-30: rgba(0, 0, 0, 0.3);
  --default-black-20: rgba(0, 0, 0, 0.2);
  --default-black-10: rgba(0, 0, 0, 0.1);
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--main);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--main-dark);
}
