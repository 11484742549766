//mixins
@mixin logo-10 {
  width: 10px;
  height: 10px;
}
@mixin logo-15 {
  width: 15px;
  height: 15px;
}
@mixin logo-20 {
  width: 20px;
  height: 20px;
}
@mixin logo-23 {
  width: 23px;
  height: 23px;
}
@mixin logo-25 {
  width: 25px;
  height: 25px;
}
@mixin logo-30 {
  width: 30px;
  height: 30px;
}
@mixin logo-40 {
  width: 40px;
  height: 40px;
}
@mixin logo-50 {
  width: 50px;
  height: 50px;
}
//transitions
@mixin transition-fast {
  transition: all ease-in-out 0.3s;
}

@mixin transition-md {
  transition: all ease-in-out 0.6s;
}

@mixin transition-slow {
  transition: all ease-in-out 0.8s;
}

@mixin text-xs {
  font-size: 10px;
}

@mixin text-sm {
  font-size: 12px;
}

@mixin text-md {
  font-size: 16px;
}

@mixin text-lg {
  font-size: 18px;
}

@mixin text-xl {
  font-size: 20px;
}

@mixin text-xxl {
  font-size: 30px;
}
@mixin text-xxxl {
  font-size: 40px;
}

//box-shadow
@mixin box-shadow-modal {
  box-shadow: -4px 10px 73px 5px rgba(0, 0, 0, 0.46);
  -webkit-box-shadow: -4px 10px 73px 5px rgba(0, 0, 0, 0.46);
  -moz-box-shadow: -4px 10px 73px 5px rgba(0, 0, 0, 0.46);
}

@mixin customScrollBarX {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-color: transparent; /* for Firefox */
  &::-webkit-scrollbar {
    width: 0px;
    scrollbar-width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: themed("main-light-70");
  }
  /* Handle */
  &::-webkit-scrollbar-track {
    background-color: transparent;
    cursor: pointer;
  }
}

@mixin customScrollBarY {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-color: transparent; /* for Firefox */
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: themed("main-light-70");
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
}
