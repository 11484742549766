@import "../../styles/index.scss";

.content-profile {
  width: 80%;
  color: #017068;
  .form-content {
    label {
      width: 35%;
      font-size: 24px;
      font-weight: 600;
    }
    input {
      min-width: 350px;
      background: #bddbd8;
      border-radius: 8px;
      border: none;
      outline-color: #5d8581d7;
      height: 40px;
      padding-left: 10px;
    }
  }
}
