@import "styles/sass/themes";
@import "styles/sass/common";
// @import "styles/sass/mediaQueries";

.uploadInput {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-left: 10px;
  padding: 20px 10px;
  position: relative;
  background: themed("main-light-70");

  input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    opacity: 0;
  }
  svg,
  img {
    object-fit: cover;
    cursor: pointer;
    width: 40%;
  }
}
