@import "./themes";
@import "./mediaQueries";
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
.mx-1 {
  margin: 0 10px;
}
.mx-2 {
  margin: 0 10px;
}
.mx-3 {
  margin: 0 15px;
}
html,
body {
  scroll-behavior: smooth;
  // color: white;
}
.theme-admin-theme_1 {
  height: 100%;
  .themed-admin-wrapper {
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;
    height: 100%;
    & > div:nth-child(3) {
      flex: 1;
      width: 100%;
      // height: 100%;
      margin: 0 auto;
      display: flex;
      padding-bottom: 50px;
      flex-direction: column;
      @include desktop-sm {
        width: 100%;
      }
    }
  }
}
.ant-select-multiple .ant-select-selection-item {
  background: transparent !important;
  background: themed("main-lighter-70") !important;
  border: none !important;
}
.cursor-pointer {
  cursor: pointer;
}
.pointerEvents-none {
  pointer-events: none;
}
.main-container {
  position: relative;
  padding: 15px 30px;
  max-width: 2100px;
  margin: auto;
  color: white;
  height: 100vh;
  margin-bottom: 55px;
  @include smartphone {
    padding: 10px 20px;
  }
}
.ant-select-selector {
  background-color: transparent !important;
}

.main-wrapper {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

.modal-container {
  .ant-modal-close-x {
    img {
      width: 15px;
      height: 15px;
    }
  }
  ant-select-selection-item {
    color: themed("default-white");
  }
  .label,
  .Upload-text p {
    color: themed("main-lighter-70");
  }

  .header-text {
    color: themed("main-lighter");
  }

  .ant-select-arrow {
    color: themed("main");
  }
  color: themed("default-white");
  input,
  textarea {
    background: transparent;

    border: 1px solid themed("main");
    outline: none !important;
    color: themed("default-white") !important;
  }
  h1,
  h2,
  h3,
  h4 {
    // color: themed("default-white");
    margin: 0;
  }

  .submit,
  .btn {
    border: 1px solid themed("main");
    padding: 5px 10px;
    border-radius: 5px;
    color: themed("default-white");
    text-transform: capitalize;
    background: themed("main");
    transition: all ease-in-out 0.3s;
    outline: none;
    cursor: pointer;
    &:hover {
      background: transparent;
    }
  }
  .ant-input:focus,
  .ant-input:hover,
  .ant-input-focused {
    border-color: themed("main-lighter-70") !important;
  }
  .ant-picker-range-separator,
  .ant-picker-separator {
    color: themed("main") !important;
  }

  .ant-input-password,
  .ant-picker {
    input {
      border: none;
    }
    background: transparent;
    border: 1px solid themed("main") !important;
  }
  .ant-checkbox-inner {
    background-color: themed("main") !important;
    border-color: themed("main") !important;
  }
  .ant-select-selector {
    background: transparent;
    color: themed("default-white");
    border: 1px solid themed("main") !important;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start)
    .ant-picker-cell-inner {
    background: themed("main-lighter") !important;
  }
  .ant-select-clear {
    background: transparent !important;
    color: themed("main-light-70");
  }
  .ant-checkbox-wrapper {
    color: themed("default-white");
    margin: 0;
  }
  .ant-form-item-label {
    label {
      color: themed("default-white");
    }
  }
  .error {
    border: 1px solid red;
  }
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: themed("main-light-30") !important;
}

.ant-picker-focused {
  border-color: none !important;
  box-shadow: none !important;
}
.ant-picker-active-bar {
  background: themed("main-lighter") !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
  color: themed("main") !important;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  display: none !important;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background: transparent !important;
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: themed("main-lighter") !important;
}
.ant-btn-primary {
  background: themed("main-lighter") !important;

  border: transparent !important;
}
.ant-tag-blue {
  padding: 4px 6px;
  color: themed("default-white") !important;
  background: themed("main-lighter") !important;
  border-color: transparent !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
  background: themed("main-lighter") !important;
}

button {
  outline: none;
}

.page-wrapper {
  padding: 50px 5%;
  background: themed("main-light-5");
}
.main-light {
  fill: themed("main-light");
}

.main-lighter {
  fill: themed("main-lighter");
}

.main-light-10 {
  background: themed("main-light-10");
}
.wrapper-modal {
  .ant-select {
    width: 100%;
  }
}
.inputsWrapper {
  display: flex;
  flex-flow: row wrap;
  > .wrapper-modal {
    width: 50%;
    flex-grow: 1;
  }
  .ant-picker.ant-picker-range {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  }
  > span {
    width: 100%;
  }
}
.prefixComp {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  width: 80%;
  margin: auto;
  .prefixItem {
    display: flex;
    align-items: center;
    width: 40%;
    border: 1px solid var(--main-light-30);
    padding: 5px 10px;
    border-radius: 5px;
  }
  > div {
    .title {
      text-align: center;
      font-size: 16px;
      color: var(--main-lighter);
      margin-top: 20px;
    }
    input {
      width: 140px;
      flex-grow: 1;
      margin-right: 5px;
      &::placeholder {
        color: var(--main-lighter);
        opacity: 0.3;
      }
    }
    > button {
      background: var(--main);
      color: var(--main-lighter);
    }
  }
}
.timerLogsRowsWrap {
  overflow-y: auto;
  height: calc(100vh - 470px);
}
.ant-select-selector {
  border-color: var(--main) !important;
  &:hover {
    border-color: var(--main) !important;
  }
}
.ant-collapse-header {
  text-transform: capitalize;
}
