@import "../../styles/index.scss";

.new-category-content {
  color: #017068;
  .category-form {
    width: 100%;
  }
  label {
    font-size: 24px;
    font-weight: 600;
  }

  select {
    min-width: 350px;
    background: #bddbd8;
    border-radius: 8px;
    border: none;
    outline-color: #5d8581d7;
    height: 40px;
    padding-left: 10px;
    color: #017068;
  }
  input {
    min-width: 350px;
    border-radius: 8px;
    border: none;
    outline: none;
    height: 40px;
    padding-left: 10px;
    color: #017068;
  }
  .active {
    background: #bddbd8;
  }
  .inactive {
    background: white;
    border-radius: 8px;
    border: solid 2px #dc3545;
  }
  .active_btn {
    background: #dc3545;
  }
  img {
    min-width: 350px;
    background: #bddbd8;
    border-radius: 8px;
    border: none;
    min-height: 170px;
    padding-left: 10px;
  }
  button {
    background: #017068;
    color: white;
    font-weight: bold;
    border-radius: 6px;
    outline: none;
    border: none;
    font-size: 18px;
  }
}
