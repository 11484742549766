@import "../../styles/index.scss";

.profile {
  label {
    color: #017068;
    font-size: 22px;
  }
  input {
    min-width: 350px;
    background: #bddbd8;
    border-radius: 8px;
    border: none;
    outline-color: #5d8581d7;
    height: 40px;
    padding-left: 10px;
  }
  .permissions {
    h4 {
      font-weight: 600;
      font-size: 22px;
      line-height: 27px;
      color: #017068;
      margin-bottom: 30px;
      margin-top: 4%;
    }

    .ant-collapse-item {
      border-bottom: none !important;
      color: #017068;
    }
    .ant-collapse {
      background: transparent;
      border: none;
      .ant-collapse-header {
        color: #000000;
      }
    }
    .ant-collapse-content {
      background-color: transparent;
    }
    .ant-collapse-header {
      padding: 10px 14px;
      font-size: 18px;
      line-height: 21px;
      span {
        color: #017068;
      }
    }
  }
}
