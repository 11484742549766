@import "../../styles/index.scss";

.create_c-content {
  color: #017068;
  .body-form {
    padding-top: 70px;
    width: 100%;
  }
  span {
    font-size: 24px;
    font-weight: 600;
  }
  input {
    border: none;
    border-bottom: 1px solid;
    outline: none;
    color: #017068;
    font-size: 18px;
  }

  .upload_input {
    input {
      width: 62%;
      height: 96%;
      opacity: 0;
    }
  }

  .btn_done {
    position: absolute;
    right: 0;
    background: rgba(225, 225, 225, 0.95);
    border: 2px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 10px 10px 16px -2px #212121,
      -12px -5px 17px -4px rgba(255, 255, 255, 0.48);
    border-radius: 24px;
    color: #017067;
    padding: 6px;
  }
}
