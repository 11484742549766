$smartphone-width: 600px;
$tablet-width: 800px;
$notebook: 980px;
$desktop-sm: 1200px;
$desktop: 1440px;

@mixin smartphone {
  @media (max-width: #{$smartphone-width}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$tablet-width}) {
    @content;
  }
}

@mixin notebook {
  @media (max-width: #{$notebook}) {
    @content;
  }
}

@mixin desktop-sm {
  @media (max-width: #{$desktop-sm}) {
    @content;
  }
}
@mixin desktop {
  @media (max-width: #{$desktop}) {
    @content;
  }
}
