//
.roles {
  width: calc(100% - 40px);
  margin: auto;
  margin-top: 20px;
  background: #00000008;
  display: flex;
  min-height: 500px;
  &-left {
    width: 30%;
    background: #00000005;
    position: relative;
    z-index: 2;
    box-shadow: 6px 0 7px -10px #000;
    .links-wrapp .catBtn .name,
    .links-wrapp .roleBtn .name {
      font-size: 16px;
    }
  }
  &-right {
    width: 70%;
    position: relative;
    z-index: 1;
    .header {
      height: 40px;
      box-shadow: 0px 3px 6px -5px #000;
      display: flex;
      align-items: center;
      > div {
        padding: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        cursor: pointer;
        margin-right: 1px;
        &.active,
        &:hover {
          background: var(--primary);
          color: var(--primary-lighter);
        }
      }
    }
  }
  &-permissions {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
    gap: 10px;
    grid-auto-flow: dense;
    padding: 15px;
    .permissionBlock {
      //   width: calc(100% / 6);
      &-header {
        background: #00000024;
        text-align: center;
        text-transform: uppercase;
        font-size: 13px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: 0.3s;
        overflow: hidden;
        &:hover,
        &.active {
          background: var(--main);
          color: #fff;
        }
      }
      &-body {
        display: flex;
        flex-flow: column;
        > div {
          display: flex;
          flex-flow: column;
          text-transform: capitalize;
          background: #464646b8;
          padding: 6px;
          margin: 1px;
          align-items: center;
          justify-content: center;
          > button {
            width: 40px;
          }
        }
      }
    }
  }
}
.noRole {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  font-size: 16px;
}
html {
  .ant-switch-checked {
    background: var(--main);
  }
}
.createRole {
  padding: 15px;
  .ant-input {
    background: #454545;
    border: 0;
    color: #fff;
  }
  .roles-permissions {
    padding: 0;
    margin-top: 10px;
  }
}
.submit {
  background: #fff;
  color: var(--main);
  border: 1px solid var(--main);
  width: 200px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  margin-top: 10px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background: var(--main);
    color: #fff;
  }
  i {
    margin-right: 10px;
  }
}
