@import "../../styles/index.scss";

.table-content {
  table {
    width: 90%;
    th {
      font-size: 26px;
      font-weight: 400;
      line-height: 3.5rem;
      border-bottom: 0.5px solid #017067;
    }
    td {
      padding-top: 15px;
      font-size: 23px;
      .dots {
        cursor: pointer;
        width: 23px;
        height: 23px;
      }
    }

    input {
      width: 200px;
      border: none;
    }
    input[type="text"]:disabled {
      background: #ececec;
    }
    .blob {
      background: white;
      border-radius: 50%;
      transform: scale(1);
      animation: pulse-green 2s infinite;
    }
    .blob.green {
      animation: pulse-green 2s infinite;
    }

    @keyframes pulse-green {
      0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(25, 124, 101, 0.7);
      }

      70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
      }

      100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
      }
    }
    .td_1 {
      width: 150px;
    }
    .check_logo {
      width: 19px;
      top: 25%;
      right: 10%;
      // opacity: 0.2;
      cursor: pointer;
    }
    .dropdown {
      position: relative;
      display: inline-block;
    }

    .dropdown-content {
      display: none;
      position: absolute;
      background: #ffffff;
      border: 1px solid #017067;
      box-sizing: border-box;
      border-radius: 10px;
      color: #017067;
      min-width: 220px;
      box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.2);
      z-index: 1;
    }

    .dropdown:hover .dropdown-content {
      display: block;
    }
  }
}

.table-content-users {
  padding: 100px;
  table {
    width: 90%;
    th {
      font-size: 26px;
      font-weight: 400;
      line-height: 3.5rem;
      color: #017067;
    }
    td {
      padding: 13px;

      font-size: 23px;
      border-bottom: 0.5px solid #017067;
    }
    img {
      width: 25px;
    }
  }
}
