.homepage {
  background-image: url("../../assets/png/background.png");
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-flow: column;
  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    height: 90px;
    letter-spacing: 0.21em;
    color: var(--main);
    display: flex;
    align-items: center;
    padding: 0 30px;
  }
  > div {
    display: flex;
    margin: auto;
    div {
      background: linear-gradient(
        146.57deg,
        rgba(58, 203, 206, 0.315) -9.85%,
        rgba(0, 0, 0, 0.315) 46.09%,
        rgba(58, 203, 206, 0.36) 112.4%
      );
      opacity: 0.9;
      backdrop-filter: blur(5px);
      border-radius: 15px;
      width: 30vw;
      height: 20vh;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      color: #ccc;
      font-size: 30px;
      margin: 20px;
      cursor: pointer;
    }
  }
}
