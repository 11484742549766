@import "styles/sass/themes";
@import "styles/sass/common";
// @import "styles/sass/mediaQueries";

.check-container {
  display: flex;

  position: relative;
  cursor: pointer;
  align-items: center;
  // justify-content: center;
  @include transition-fast;
  margin: 4px 0;
  p {
    margin: 0;
    width: auto;
    margin-left: 4px;
  }
  .check-icon {
    background-color: themed("main-light");
    color: themed("default-white");
    margin-right: 5px;
    width: 15px;
    height: 15px;
    position: relative;
    background: var(--main);
    color: #fff;
    span {
      position: absolute;
      opacity: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @include transition-fast;
    }
    .active {
      opacity: 1;
    }
  }
}
