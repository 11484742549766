@font-face {
  font-family: "Montserrat", sans-serif;
  src: url(./Montserrat-Light.ttf) format("tff");
  font-display: auto;
  font-weight: 300;
}

@font-face {
  font-family: "Montserrat", sans-serif;
  src: url(./Montserrat-Regular.ttf) format("tff");
  font-display: auto;
  font-weight: 400;
}

@font-face {
  font-family: "Montserrat", sans-serif;
  src: url(./Montserrat-Medium.ttf) format("tff");
  font-display: auto;
  font-weight: 500;
}

@font-face {
  font-family: "Montserrat", sans-serif;
  src: url(./Montserrat-SemiBold.ttf) format("tff");
  font-display: auto;
  font-weight: 600;
}

@font-face {
  font-family: "Montserrat", sans-serif;
  src: url(./Montserrat-Bold.ttf) format("tff");
  font-display: auto;
  font-weight: bold;
}

body {
  font-family: "Montserrat", sans-serif !important;
}

.font-light {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 300;
}
.font-regular {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 400;
}
.font-medium {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500;
}
.font-sm-bold {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 600;
}
.font-bold {
  font-family: "Montserrat", sans-serif !important;
  font-weight: bold;
}
