.vars1 {
  // background: red;
  &:root {
    //main Colors
    --main: #12727e;
    --main-dark: #0f5f69;
    --primary: #12727e;
    --primary-20: #12717e54;
    --primary-8: #12717e17;
    --primary-light: #68ffff3d;
    --primary-lighter: #b4d6da;
    --primary-lighter-x: #8ec1c7;
    --primary-lighter-xl: #244a4f87;
    --primary-dark: #12727e;
    --primary-darker: #103d43;
    --secondary: #7b3d27;
    --secondary-60: rgba(91, 36, 15, 0.65);
    //other colors
    --default-white: rgb(255, 255, 255);
    --default-black: #000;
    --default-black-30: rgba(0, 0, 0, 0.3);
    --default-black-20: rgba(0, 0, 0, 0.2);
    --default-black-10: rgba(0, 0, 0, 0.1);
  }
}
