@import "../styles/index.scss";

.lay_1-content {
  h1 {
    font-weight: bold;
    color: #017067;
    font-size: 45px;
    margin-bottom: 0;
  }

  .dropdown {
    display: inline-block;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background: #e8e8e8;
    box-shadow: -2px 2px 4px rgba(56, 203, 206, 0.45);
    color: #017067;
    min-width: 280px;
    right: 2%;
    z-index: 1;
    select {
      background: #c6c6c6;
      color: white;
      font-size: 12px;
      border: none;
      border-radius: 6px;
      padding: 5px;
      outline: none;
      min-width: 145px;
    }

    .btn_chose {
      position: relative;
      button {
        border: 1px solid #017068;
        box-sizing: border-box;
        border-radius: 6px;
        color: #017067;
        font-size: 14px;
        padding: 5px;
        position: inherit;
      }
      input {
        position: absolute;
        opacity: 0;
        font-size: 18px;
        width: 91px;
        left: 3%;
      }
    }
    .btn_done {
      margin-left: auto;
    }
  }

  .dropdown .dropdown-content {
    display: flex;
    flex-direction: column;
  }

  button {
    font-size: 30px;
    color: white;
    background: transparent;
    font-weight: bold;
    border: none;
    position: absolute;
    top: 10%;
    right: 3%;
  }
}

.lay_2-content {
  max-width: 90%;
  h2 {
    color: #017067;
  }

  input {
    width: 250px;
    height: 40px;
    border-radius: 50px;
    border: 1px solid #01480c;
    padding-left: 50px;
    box-sizing: border-box;
    outline: none;
  }
  .search-input {
    img {
      position: absolute;
      right: 83.16%;
      top: 22%;
    }
  }
}

.lay_3-content {
  background: #bddbd8;
  .text-header {
    text-decoration: none;
    color: white;
  }
  h3 {
    margin-bottom: 0;
  }
  .active {
    color: #00544e;
  }
}
